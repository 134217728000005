<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener los Usuarios {{ error }}
      </div>
    </b-alert>

    <div style="text-align: center; margin: 45vh 0" v-if="loading && rows.length === 0">
      <b-spinner label="Loading..." />
    </div>

    <b-row v-if="rows.length > 0">
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Usuarios</b-card-title>
            <b-button @click="crearUsuario" variant="primary" :disabled="loading">
              Crear
            </b-button>
          </b-card-header>

          <b-card-body>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input type="text" class="d-inline-block" v-model="searchTerm" placeholder="Buscar" />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table min-height="300px" :rows="rows" :columns="columns" :sort-options="{
              enabled: false,
            }" :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }" :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }" :isLoading.sync="loading">
              <template slot="table-row" slot-scope="props">
                <!-- Column: Estado -->
                <span v-if="props.column.field === 'estatus'">
                  <b-badge :variant="estadoVariant(props.row.estatus)">
                    {{ estadoText(props.row.estatus) }}
                  </b-badge>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item @click="onUserClick(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="onPasswordClick(props.row)">
                        <feather-icon icon="KeyIcon" class="mr-50" />
                        <span>Cambiar Password</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="onDeleteClick(props.row)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Eliminar</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <!-- Column: Otras Columnas -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Cantidad de Usuarios</span>
                    <b-form-select v-model="pageLength" :options="['10', '20', '30']" class="mx-1" @input="(value) =>
                      props.perPageChanged({ currentPerPage: value })
                      " />
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                        ">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <crear-editar-usuario v-if="selectedUser !== null" :user="selectedUser" :refreshData="getUsersData" />
        <div v-else class="iconEmpty">
          <feather-icon icon="UserIcon" size="100" />
        </div>
      </b-col>
    </b-row>
    <!-- modal Error-->
    <b-modal title="Error" v-model="showErrorModal" ok-only ok-title="Accept" ok-variant="danger"
      modal-class="modal-danger" centered>
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
    <!-- modal cambio password -->
    <b-modal id="modal-cpass" @ok="savePassword" :title="chPassTittle" v-model="showPasswordModal" centered
      ok-title="Cambiar" cancel-title="Cancelar" cancel-variant="outline-secondary">
      <b-form-group>
        <label for="newpass">Nueva Password:</label>
        <b-form-input id="newpass" type="text" v-model="chPassUser.password" placeholder="Password"
          autocomplete="off" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BButton,
  BSpinner,
  BDropdown,
  BCardText,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import CrearEditarUsuario from "./CrearEditarUsuario.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken, getRoleUser } from "@/auth/utils"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BButton,
    BSpinner,
    BDropdown,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BPagination,
    BCardHeader,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    CrearEditarUsuario,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      marcas: [],
      rows: [],
      error: "",
      loading: true,
      pageLength: 10,
      searchTerm: "",
      chPassUser: { password: "" },
      selectedUser: null,
      showErrorModal: false,
      showPasswordModal: false,
      columns: [
        {
          label: "Rol",
          field: "rol",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Apellido",
          field: "apellido",
        },
        {
          label: "Estado",
          field: "estatus",
        },
        {
          label: "Acciones",
          field: "actions",
        },
      ],
      tiendasUsuario: []
    };
  },
  async mounted() {
    this.getUsersData();
  },
  computed: {
    chPassTittle() {
      return `Cambiar Password a [${this.chPassUser.nombre} ${this.chPassUser.apellido}] [${this.chPassUser.email}]`;
    },
    estadoText() {
      return (s) => (s === "A" ? "Activo" : "Inactivo");
    },
    estadoVariant() {
      return (s) => (s === "A" ? "light-success" : "light-secondary");
    },
  },
  methods: {
    crearUsuario() {
      this.selectedUser = null;
      this.$nextTick(() => {
        this.selectedUser = {
          email: "",
          codRol: "",
          activo: false,
          nombre: "",
          marcas: [],
          tiendas: [],
          bodegas: [],
          apellido: "",
          idCliente: 0,
        };
      });
    },
    onUserClick(user) {
      this.selectedUser = null;
      const userAUX = JSON.parse(JSON.stringify(user));
      userAUX.activo = userAUX.estatus === "A";
      userAUX.marcas = userAUX.lsmarcas.map((m) => m.id);
      userAUX.tiendas = userAUX.lsTiendas.map((t) => t.id);
      userAUX.bodegas = userAUX.lsBodegas.map((b) => b.id);
      this.$nextTick(() => {
        this.selectedUser = userAUX;
      });
    },
    onPasswordClick(user) {
      this.chPassUser = user;
      this.showPasswordModal = true;
    },
    onDeleteClick(user) {
      this.$nextTick(() => {
        this.selectedUser = null;
      });
      this.$bvModal
        .msgBoxConfirm(
          `Esta seguro que desea eliminar el usuario ${user.nombre} ${user.apellido}?`,
          {
            size: "sm",
            title: "Eliminar usuario",
            okTitle: "Eliminar",
            centered: true,
            okVariant: "danger",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
          }
        )
        .then((value) => {
          if (value) {
            this.deleteUser(user.idCliente);
          }
        });
    },
    deleteUser(userID) {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CURDUsuariosMiTienda",
          body: JSON.stringify({
            opt: "DD",
            cliente: userID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            await this.getUsersData();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Éxito",
                icon: "CheckIcon",
                text: "Se elimino correctamente el user.",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al eliminar el usuario. ${e.message}`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    getUsersData() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/ListUsersStore",
            body: JSON.stringify({
              empresa: 2,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.rows = res.data.lsUser;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            this.showErrorModal = true;
            reject();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    savePassword() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UpdateClaveUsuariosMiTienda",
          body: JSON.stringify({
            cliente: this.chPassUser.idCliente,
            password: this.chPassUser.password,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                text: `Se cambio correctamente el password del usuario ${this.chPassUser.email}.`,
                icon: "CheckIcon",
                title: "Éxito",
                variant: "success",
              },
            });
            this.chPassUser = { password: "" };
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al cambiar el password del usuario. ${e.message}`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "success",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}

.vgt-responsive {
  min-height: 250px;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
