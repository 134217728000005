<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          user.idCliente === 0 ? "Crear Usuario" : "Modificar Usuario"
        }}</b-card-title>
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox id="vi-activo" v-model="user.activo" class="custom-control-primary" name="check-button"
            switch />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input id="vi-nombre" placeholder="Nombre" v-model="user.nombre" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Apellido" label-for="vi-apellido">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input id="vi-apellido" placeholder="Apellido" v-model="user.apellido" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="vi-email">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input id="vi-email" placeholder="Email" v-model="user.email" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Rol" label-for="vi-rol">
            <v-select id="vi-rol" v-model="user.codRol" label="name" :reduce="(rol) => rol.code"
              :options="allowedRolesByUser" :clearable="false" @option:selected="onChangeRole" />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="showStoreSelect && this.user.codRol !== 'AD'">
          <b-form-group label="Tiendas" label-for="vi-tiendas">
            <v-select id="vi-tiendas" label="nombre" v-model="user.tiendas" :reduce="(tienda) => tienda.id"
              :options="tiendas" :loading="loadingTiendas" :disabled="loadingTiendas" :clearable="false"
              :filterable="true" multiple />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="showWarehouseSelect">
          <b-form-group label="Bodegas" label-for="vi-bodegas">
            <v-select id="vi-bodegas" label="nombre" v-model="user.bodegas" :reduce="(bodega) => bodega.id"
              :options="bodegas" :loading="loadingBodegas" :disabled="loadingTiendas" :clearable="false"
              :filterable="true" multiple />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="showStoreSelect && this.user.codRol === 'AD'">
          <b-form-group label="Marcas" label-for="vi-marcas">
            <v-select id="vi-marcas" label="name" v-model="user.marcas" :reduce="(marca) => marca.id"
              :options="opcionesMarcas" :loading="loadingMarcas" :disabled="loadingTiendas" :clearable="false"
              :filterable="true" multiple />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button block @click="saveData" variant="primary" class="mr-1" :disabled="loading">
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadioGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";
import {
  isManager,
  isSuperUser,
  getAuthToken,
  isAdministrator,
} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    BSpinner,
    BCardBody,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BInputGroupPrepend,
    ToastificationContent,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      roles: [
        { name: "Super Admin", code: "SA" },
        { name: "Administrador", code: "AD" },
        { name: "Gerente", code: "GE" },
        { name: "Cajero", code: "CA" },
        { name: "Call Center", code: "CC" },
        { name: "Supervisor", code: "SU" },
        { name: "Despachador", code: "DE" },
        { name: "Shopper", code: "PS" },
        { name: "Picker", code: "PK" }
      ],
      tiendas: [],
      bodegas: [],
      loading: false,
      loadingMarcas: false,
      loadingTiendas: false,
      loadingBodegas: false,
      opcionesMarcas: [],
      excludedStored: [],
      excludedBrands: [],
      excludedWarehouses: [],
      tipoEstablecimientoDefault: 'Bodegas'
    };
  },
  mounted() {
    this.onChangeRole();
  },
  methods: {
    onChangeRole() {
      switch (this.user.codRol) {
        case "GE":
        case "CA":
        case "DE":
          this.getTiendasData();
          break;
        case "AD":
          this.fetchMarcas();
          break;
        case "SU":
        case "PK":
          this.getBodegasData();
          break;
        default:
          break;
      }
    },
    fetchMarcas() {
      this.loadingMarcas = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UserStoresBrand",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.opcionesMarcas = res.data;
          if (this.user.idCliente !== 0) {
            this.excludedBrands = this.user.marcas.filter(b =>
              res.data.lsTiendas.some(mb => mb.id === b)
            ).map((b) => b.id);
            this.user.marcas = this.user.marcas.filter(b =>
              !this.excludedBrands.includes(b)
            );
          }
          this.loadingMarcas = false;
        })
        .catch((e) => {
          this.error = e.message;
          this.loadingMarcas = false;
        });
    },
    getTiendasData() {
      if (this.tiendas.length === 0) {
        this.loadingTiendas = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserStores",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loadingTiendas = false;
            if (res.data.bOk) {
              this.tiendas = res.data.lsTiendas;
              if (this.user.idCliente !== 0) {
                this.excludedStored = this.user.tiendas.filter(s =>
                  res.data.lsTiendas.some(mu => mu.id === s)
                )
                this.excludedStored = [...new Set(this.user.excludedStored)];
                this.user.tiendas = [...new Set(this.user.tiendas)];              
              }
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.loadingTiendas = false;
            this.error = e.message;
          });
      }
    },
    getBodegasData() {
      if (this.bodegas.length === 0) {
        this.loadingBodegas = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/ConsultaBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.bodegas;
              if (this.user.idCliente !== 0) {
                this.excludedWarehouses = this.user.bodegas.filter(w =>
                  res.data.bodegas.some(mw => mw.id === w)
                ).map((w) => w.id);
                this.user.bodegas = this.user.bodegas.filter(w =>
                  !this.excludedWarehouses.includes(w)
                );
              }
            } else {
              this.error = res.data.mensaje;
            }
            this.loadingBodegas = false;
          })
          .catch((e) => {
            this.error = e.message;
            this.loadingBodegas = false;
          });
      }
    },
    saveData(e) {
      e.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CURDUsuariosMiTienda",
          body: JSON.stringify({
            opt: this.user.idCliente === 0 ? "I" : "U",
            rol: this.user.codRol,
            email: this.user.email,
            nombre: this.user.nombre,
            marcas: [...this.excludedBrands, ...this.user.marcas].join(","),
            tiendas: [...this.excludedStored, ...this.user.tiendas].join(","),
            bodegas: [...this.excludedWarehouses, ...this.user.bodegas].join(","),
            cliente: this.user.idCliente,
            estatus: this.user.activo ? "A" : "I",
            apellido: this.user.apellido,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.refreshData();
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Éxito",
                  icon: "CheckIcon",
                  text:
                    this.user.idCliente === 0
                      ? "Se creo el Usuario correctamente"
                      : "Se actualizo correctamente el Usuario.",
                  variant: "success",
                },
              });
            }, 500);
            if (this.user.idCliente === 0) {
              this.user.idCliente = res.data.iDtemp;
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                this.user.idCliente === 0
                  ? `Ocurrió un error al Crear el Usuario (${e.message}).`
                  : `Ocurrió un error al actualizar el Usuario (${e.message}).`,
              variant: "danger",
            },
          });
          this.loading = false;
        });
    },
  },
  computed: {
    isManager,
    isSuperUser,
    isAdministrator,
    allowedRolesByUser() {
      if (this.isSuperUser) {
        return this.roles;
      }
      if (this.isManager) {
        return this.roles.filter(r => ["GE", "CA"].includes(r.code));
      }
      if (this.isAdministrator) {
        return this.roles.filter(r => ["AD", "GE", "CA"].includes(r.code));
      }
      return [];
    },
    showStoreSelect() {
      return ["GE", "CA", "AD", "DE"].some((r) => r === this.user.codRol);
    },
    showWarehouseSelect() {
      return ["PK", "SU"].some((r) => r === this.user.codRol);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
