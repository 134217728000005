var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.user.idCliente === 0 ? "Crear Usuario" : "Modificar Usuario"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.user.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "activo", $$v);
      },
      expression: "user.activo"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.user.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "nombre", $$v);
      },
      expression: "user.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Apellido",
      "label-for": "vi-apellido"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-apellido",
      "placeholder": "Apellido"
    },
    model: {
      value: _vm.user.apellido,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "apellido", $$v);
      },
      expression: "user.apellido"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "vi-email"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.user.email,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rol",
      "label-for": "vi-rol"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-rol",
      "label": "name",
      "reduce": function reduce(rol) {
        return rol.code;
      },
      "options": _vm.allowedRolesByUser,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onChangeRole
    },
    model: {
      value: _vm.user.codRol,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "codRol", $$v);
      },
      expression: "user.codRol"
    }
  })], 1)], 1), _vm.showStoreSelect && this.user.codRol !== 'AD' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tiendas",
      "label-for": "vi-tiendas"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tiendas",
      "label": "nombre",
      "reduce": function reduce(tienda) {
        return tienda.id;
      },
      "options": _vm.tiendas,
      "loading": _vm.loadingTiendas,
      "disabled": _vm.loadingTiendas,
      "clearable": false,
      "filterable": true,
      "multiple": ""
    },
    model: {
      value: _vm.user.tiendas,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "tiendas", $$v);
      },
      expression: "user.tiendas"
    }
  })], 1)], 1) : _vm._e(), _vm.showWarehouseSelect ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bodegas",
      "label-for": "vi-bodegas"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-bodegas",
      "label": "nombre",
      "reduce": function reduce(bodega) {
        return bodega.id;
      },
      "options": _vm.bodegas,
      "loading": _vm.loadingBodegas,
      "disabled": _vm.loadingTiendas,
      "clearable": false,
      "filterable": true,
      "multiple": ""
    },
    model: {
      value: _vm.user.bodegas,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "bodegas", $$v);
      },
      expression: "user.bodegas"
    }
  })], 1)], 1) : _vm._e(), _vm.showStoreSelect && this.user.codRol === 'AD' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Marcas",
      "label-for": "vi-marcas"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-marcas",
      "label": "name",
      "reduce": function reduce(marca) {
        return marca.id;
      },
      "options": _vm.opcionesMarcas,
      "loading": _vm.loadingMarcas,
      "disabled": _vm.loadingTiendas,
      "clearable": false,
      "filterable": true,
      "multiple": ""
    },
    model: {
      value: _vm.user.marcas,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "marcas", $$v);
      },
      expression: "user.marcas"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }